import styled from '@emotion/styled';

const ResetPasswordStyle = styled.div`
  position: relative;
  background: url('/reset-password/resetpwd_background.png');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 958px;

  .main__container {
    position: absolute;
    max-width: 370px;
    width: 100%;
    top: 83px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    .main__logo {
      text-align: center;
      .logo {
        max-width: 181px;
        width: 100%;
      }
    }
    .form__group {
      width: 100%;
      background: #fff;
      padding: 37px 50px;
      box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.18);
      border-radius: 30px;
      margin-top: 38px;
      .header__form {
        margin-top: 10px;
        .text__title,
        .text__sub {
          margin: 0;
          padding: 0;
        }
        .text__title {
          color: #1d2332;
          font-size: 24px;
          font-family: RubikMedium;
          line-height: 30px;
        }
        .text__sub {
          color: #a5a7ad;
          font-size: 13px;
          line-height: 18px;
          margin-top: 6px;
        }
      }
      .body__form {
        margin: 26px 0 0 0;
        .input__control {
          input {
            padding: 29px 20px 19px 20px;
            font-family: RubikMedium;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }

      .footer__form {
        margin: 30px 0 10px 0;
        .button__control {
          padding: 14px 2.4em;
          margin: 0;
          outline: none;
          span {
            color: #1d2332;
            font-family: RubikMedium;
            font-size: 15px;

            line-height: 18px;
          }

          .icon--svg {
            transform: translateX(80%);
          }
        }
      }
    }
  }
  @media screen and (max-width: 576px) {
    .main__container {
      max-width: 305px;
      .form__group {
        padding: 20px 25px;
      }
    }
  }
`;

export { ResetPasswordStyle };
