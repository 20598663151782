import React, { FunctionComponent, useState } from 'react';

import Layout from '../../layouts/Layout';
import { ResetPasswordStyle } from './ResetPasswordPageStyle';
import Input from '../../components/Form/Input';
import Button from '../../components/Form/Button';
import { Helmet } from 'react-helmet';

const ResetPassword: FunctionComponent<{}> = () => {
  const [isConfirmEmailSent, setConfirmEmailSent] = useState(false);

  const onHandleClick = () => {
    setConfirmEmailSent(!isConfirmEmailSent);
  };
  return (
    <Layout>
      <ResetPasswordStyle>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Reset password</title>
        </Helmet>
        <div className="main__container">
          <div className="main__logo">
            <img className="logo" src="/logo-white.svg" alt="Logo White" />
          </div>
          <div className="form__group">
            <div className="header__form">
              <p className="text__title">{isConfirmEmailSent ? `Password reset!` : `Lost your password?`}</p>
              <p className="text__sub">
                {isConfirmEmailSent
                  ? `An email was sent to your address. Follow the instructions to set a new password.`
                  : `Enter your email address below to recieve a password reset email.`}
              </p>
            </div>
            <div className="body__form">
              {isConfirmEmailSent ? `` : <Input type="email" className="input__control" label="Email" fontSize={1} />}
            </div>
            <div className="footer__form">
              <Button
                label={isConfirmEmailSent ? `Resend the email` : `Reset your password`}
                icon={<img src="/icon/right_arrow.svg" alt="right_arrow.svg" className="icon--svg" />}
                className="button__control"
                onClick={onHandleClick}
              />
            </div>
          </div>
        </div>
      </ResetPasswordStyle>
    </Layout>
  );
};

export default ResetPassword;
